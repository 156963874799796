body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: pixelated;
  -moz-osx-font-smoothing: none;
  
}
.App{

  margin:0;
  padding: 0;
  height: 100vh;
  position:relative;
}
image{
  image-rendering: pixelated;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
.box{
  background-color: beige !important;
  color: rgb(33, 163, 120);
  &:hover{
    background-color: rgb(179, 179, 153) !important;
    cursor: pointer;
    animation: rotar1 .5s 1;
    -moz-animation: rotar1 .5s 1;
    -o-animation: rotar1 .5s 1;
    -webkit-animation: rotar1 .5s 1;
}
}
.generic-modal {
  z-index: 7000;
  // background-color: white;
  position:relative;
  max-height:100vh !important;
  max-width: 100vw !important;
  .content {
    overflow-y: auto !important;
  }
  .modal-close-button {
    border-radius: 22px;
    width: 44px;
    height: 44px;
    background-color: #e74c3c;
    right: -22px;
    top: -22px;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
    svg {
      font-size: 2.5em;
      transition: inherit;
    }
    &:hover {
      background-color: #c0392b;
      svg {
        font-size: 2em;
        transform: rotate(90deg);
      }
    }
  }
}
.modal-close-button {
  border-radius: 22px;
  width: 22px;
  height: 22x;

  right: -11px;
  top: -11px;
  transition: 0.3s all ease-in-out;

  
  
}

.ReactModal__Overlay {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

// Modal fade animation
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  position: absolute;
  z-index: 7000;
  background-color:rgba(138, 138, 138, 0) !important;
  backdrop-filter: blur(3px) !important;
  -webkit-backdrop-filter:  blur(3px);
  
}


.ReactModal__Overlay--before-close {
  opacity: 0;
}

// Prevent unecessary scrolling when modal is open
.ReactModal__Body--open,
.ReactModal__Html--open {
  overflow: hidden;
  .cues-container {
    z-index: -1 !important;
    * {
      z-index: inherit;
    }
  }
  .fixed-top {
    z-index: 0 !important;
  }
}


.font-12{
  font-size: xx-large;
}

.barrel-roll{
  animation: rotar 5s 1;
  -moz-animation: rotar 5s 1;
  -o-animation: rotar 5s 1;
  -webkit-animation: rotar 5s 1;
}
@keyframes rotar{
  from{
    transform: rotateZ(0deg);
  }
  to{
    transform: rotateZ(360deg);
  }
}
@-moz-keyframes rotar{
  from{
    -moz-transform: rotateZ(0deg);
  }
  to{
    -moz-transform: rotateZ(360deg);
  }
}
@-o-keyframes rotar{
  from{
    -o-transform: rotateZ(0deg);
  }
  to{
    -o-transform: rotateZ(360deg);
  }
}
@-webkit-keyframes rotar{
  from{
    -webkit-transform: rotateZ(0deg);
  }
  to{
    -webkit-transform: rotateZ(360deg);
  }
}
@keyframes rotar1{
  0%{
        transform: rotateZ(0deg);
  }
  25%{
   transform: rotateZ(5deg);
  }

  75%{
   transform: rotateZ(-5deg);
  }
  100%{
   transform: rotateZ(0deg);
  }
}
@-moz-keyframes rotar1{
  0%{
    -moz-transform: rotateZ(0deg);
  }
  25%{
    -moz-transform: rotateZ(5deg);
  }

  75%{
    -moz-transform: rotateZ(-5deg);
  }
  100%{
    -moz-transform: rotateZ(0deg);
  }
}
@-o-keyframes rotar1{
  0%{
     -o-transform: rotateZ(0deg);
  }
  25%{
    -o-transform: rotateZ(5deg);
  }

  75%{
    -o-transform: rotateZ(-5deg);
  }
  100%{
    -o-transform: rotateZ(0deg);
  }
}
@-webkit-keyframes rotar1{
  0%{
     -webkit-transform: rotateZ(0deg);
  }
  25%{
    -webkit-transform: rotateZ(5deg);
  }

  75%{
    -webkit-transform: rotateZ(-5deg);
  }
  100%{
    -webkit-transform: rotateZ(0deg);
  }
}